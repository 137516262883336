/* Avoid adding styles to this file; prefer @material-ui/core/styles instead. */

html {
  font-size: 14px;
}

@media (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
